import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Layout from "../components/layout/Layout";
import ProductRow from "../components/cart/ProductRow";

import useStore from "../context/StoreContext";
import { Link, navigate } from "gatsby";

const Cart = () => {
  const { cart, checkout } = useStore()

  const [totalPrice, setTotalPrice] = useState(0)
  const [cgvAccepted, setCgvAccepted] = useState(false)

  useEffect(() => {
      let total = 0
      if(checkout) {
        checkout.lineItems.map((item, index) => {
          total = total + item.attrs.variant.priceV2.amount*item.attrs.quantity.value
        })
        setTotalPrice(total)
      }
    }
    , [checkout])

  const handleInputChange = () => {
    setCgvAccepted(!cgvAccepted)
  }

  const goToCheckout = () => {
    if(cgvAccepted) {
      navigate(checkout.webUrl)
    }
  }

  return (
    <Layout>
      <div className="content">
        <section className="section section--gradient">
          <div className="container">
            <h2 className="is-flex is-justify-content-center is-centered is-bold" style={{ marginBottom : "4rem"}}>Votre panier</h2>
            { checkout.lineItems.length === 0 ?
              <>
                <div className="is-flex is-justify-content-center is-centered">
                  <div className="column">
                    <div className="row is-flex is-justify-content-center is-centered" style={{ marginBottom : "4rem"}}>
                      <p>Votre panier est vide.</p>
                    </div>
                    <div className="row is-flex is-justify-content-center is-centered">
                      <Link className="button has-text-terracota is-link" style={{ backgroundColor: 'black', marginTop : "2rem"}}  to={"/atelier-type"}>
                        Voir les ateliers
                        <svg aria-hidden="true" focusable="false" role="presentation" fill="#A8563A" className="icon icon--wide icon-arrow-right" viewBox="0 0 20 8" style={{ marginLeft : "10px", color: "white"}}>
                          <path
                            d="M15.186.445c.865.944 1.614 1.662 2.246 2.154.631.491 1.227.857 1.787 1.098v.44a9.933 9.933 0 0 0-1.875 1.196c-.606.485-1.328 1.196-2.168 2.134h-.752c.612-1.309 1.253-2.315 1.924-3.018H.77v-.986h15.577c-.495-.632-.84-1.1-1.035-1.406-.196-.306-.486-.843-.87-1.612h.743z"
                            fill="white" fill-rule="evenodd"></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
              :
              <Wrapper>
                <HeaderWrapper>
                  <Text className="is-centered" style={{ textAlign: "center"}}>Produit(s)</Text>
                  <Text className="has-text-right is-hidden-mobile mobile-hidden">Prix</Text>
                  <Text className="has-text-right is-hidden-mobile mobile-hidden">Quantité</Text>
                  <Text className="has-text-right cart-total-mobile">Total</Text>
                </HeaderWrapper>
                {
                  checkout && checkout.lineItems.length > 0 ? checkout.lineItems.map((item, index) => <ProductRow key={index} item={item} product={cart[index]} />) : <Text>Votre panier est vide.</Text>
                }
                <ButtonWrapper>

                  <div className="rows">
                    <div className="row">
                      <label className="" style={{ marginTop : "20px"}}>
                        J'accepte les
                        <Link href="/conditions-de-vente">
                          <a className="has-text-terracota" target="_blank" rel="noreferrer noopener"> conditions générales de vente</a>
                        </Link>
                      </label>
                      <input style={{width: "50px", marginBottom : "10px"}}
                             type={"checkbox"}
                             value={cgvAccepted}
                             onChange={handleInputChange}
                             className="input-quantity"
                      />
                    </div>
                    <div className="row">
                      <h5 className="has-text-right" style={{ margin : 0}}>Sous-total : {totalPrice} €</h5>
                      <p className="is-size-6 has-text-right" style={{ color : "grey", fontStyle : "italic", marginBottom :"0.5rem" }}>Taxes incluses.</p>
                    </div>

                    <div className="row has-text-right">
                      <button className="button has-text-terracota is-link" disabled={!cgvAccepted} style={{ backgroundColor: 'black', marginTop : "20px"}} onClick={goToCheckout}>
                        Passer a la caisse
                      </button>
                    </div>
                  </div>
                </ButtonWrapper>
              </Wrapper>
            }
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Cart

const Wrapper = styled.div`
  margin: 1rem;
`

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr 1fr 1fr;
  gap: 0.5rem;
`

const Text = styled.p`
  font-weight: 600;
  font-size: 14px;
  min-width : 50px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`