
import React, { useEffect, useState } from "react";
import styled from "styled-components"
import useStore from "../../context/StoreContext";
import { Link } from "gatsby";
import paragraphs from "lines-to-paragraphs";

const ProductRow = ({ item, product }) => {

    const { cart } = useStore()


  const [descriptionHtml, setDescriptionHtml] = useState("")
  const [handle, setHandle] = useState("")
  const [comment, setComment] = useState("")


  useEffect(() => {
    cart.forEach((cartItem) => {
      if(item.attrs.variant.id === cartItem.product.variants[0].shopifyId) {
        setDescriptionHtml(cartItem.product.descriptionHtml)
        setHandle(cartItem.product.handle)
      }
    })

      if(item && item.customAttributes &&  item.customAttributes[1] &&  item.customAttributes[1].attrs.key.value === "Comment") {
        setComment(item.customAttributes[1].attrs.value.value)
      }
  }, [cart])

  const variantId = item.attrs.variant.id
  const title = item.attrs.title.value
  const quantity = item.attrs.quantity.value
  const price  = item.attrs.variant.priceV2.amount
  const image  = item.attrs.variant.image.src

  const { removeLineItem } = useStore()

  return (
    <Wrapper>
      <ProductWrapper>
        <div className="columns is-full">
          <Link to={"/ateliers/" + handle}>
            <Image src={image} alt={title} style={{margin : "1rem"}} />
          </Link>
          <div className="column is-justify-content-center">
            <Subtitle>
              <Link to={"/ateliers/" + handle} style={{ marginTop: 10, width : "100px", color: "black"}}>
                {title}
              </Link>
            </Subtitle>
            <Subtitle>
              <div dangerouslySetInnerHTML={{ __html: paragraphs(descriptionHtml) }} style={{ marginTop: 10 }} />
            </Subtitle>
            {comment.length > 0 &&
            <Subtitle>
              <p>Commentaires : {comment}</p>
            </Subtitle>
            }
            <DeleteButton style={{textDecoration: "underline", marginBottom : "10px", borderRadius: "25px", width: "250px", fontSize: "14px" }} onClick={() => removeLineItem(variantId)}>Retirer du panier</DeleteButton>
          </div>
        </div>
      </ProductWrapper>
      <Subtitle className="has-text-right mobile-hidden is-hidden-mobile">{price} €</Subtitle>
      <Subtitle className="has-text-right mobile-hidden is-hidden-mobile">{quantity}</Subtitle>
      <Subtitle className="has-text-right">{quantity*price} €</Subtitle>
    </Wrapper>
  )
}

export default ProductRow

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr 1fr 1fr;
  gap: 0.5rem;
  align-items: center;
  border-bottom : 1px solid black;
  margin-bottom : 20px;
  padding-bottom: 20px;
`

const ProductWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  align-items: center;
`

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 20px;
`

const Subtitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  min-width : 50px;
`

const DeleteButton = styled.p`
  color: #A8563A;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-line: underline;
`